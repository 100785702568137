(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['level'] = template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"step\" id=\"page-level"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"level") : depths[1]), depth0))
    + "-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"subject") : depths[1]), depth0))
    + "-step"
    + alias2((lookupProperty(helpers,"inc")||(depth0 && lookupProperty(depth0,"inc"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":9,"column":74},"end":{"line":9,"column":90}}}))
    + "\" data-subject=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"fag") || (depth0 != null ? lookupProperty(depth0,"fag") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"fag","hash":{},"data":data,"loc":{"start":{"line":9,"column":106},"end":{"line":9,"column":115}}}) : helper)))
    + "\">\n        <div class=\"container content-wrapper\">\n          <header class=\"row\">\n            <h2>"
    + alias2(((helper = (helper = lookupProperty(helpers,"fag") || (depth0 != null ? lookupProperty(depth0,"fag") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"fag","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":25}}}) : helper)))
    + ": Opgave <span class=\"current\">"
    + alias2((lookupProperty(helpers,"inc")||(depth0 && lookupProperty(depth0,"inc"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":72}}}))
    + "</span> <span class=\"total\">/ "
    + alias2((lookupProperty(helpers,"length")||(depth0 && lookupProperty(depth0,"length"))||alias4).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"questions") : depths[1]),{"name":"length","hash":{},"data":data,"loc":{"start":{"line":12,"column":102},"end":{"line":12,"column":127}}}))
    + "</span></h2>\n\n            <div class=\"col\">\n              <div class=\"status-bar\">\n                <div class=\"status-bar-progress\" style=\"width: calc(100% / "
    + alias2((lookupProperty(helpers,"length")||(depth0 && lookupProperty(depth0,"length"))||alias4).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"questions") : depths[1]),{"name":"length","hash":{},"data":data,"loc":{"start":{"line":16,"column":75},"end":{"line":16,"column":100}}}))
    + " * "
    + alias2((lookupProperty(helpers,"inc")||(depth0 && lookupProperty(depth0,"inc"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":16,"column":103},"end":{"line":16,"column":119}}}))
    + ");\"></div>\n              </div>\n            </div>\n          </header>\n          <div class=\"row\">\n            <div class=\"col-12 col-lg-8\">\n              <p>\n                <b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"fag") || (depth0 != null ? lookupProperty(depth0,"fag") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"fag","hash":{},"data":data,"loc":{"start":{"line":23,"column":19},"end":{"line":23,"column":28}}}) : helper)))
    + "</b><br>\n                <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"video") || (depth0 != null ? lookupProperty(depth0,"video") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"video","hash":{},"data":data,"loc":{"start":{"line":24,"column":25},"end":{"line":24,"column":36}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"videotekst") || (depth0 != null ? lookupProperty(depth0,"videotekst") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"videotekst","hash":{},"data":data,"loc":{"start":{"line":24,"column":54},"end":{"line":24,"column":70}}}) : helper)))
    + "</a><br><br>\n              </p>\n            </div>\n          </div>\n\n          <div class=\"row\">\n            <div class=\"col-12 col-lg-8\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "<!--\n                <div class=\"media-wrapper\">\n\n   <iframe id=\"video-level"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"level") : depths[1]), depth0))
    + "-step"
    + alias2((lookupProperty(helpers,"inc")||(depth0 && lookupProperty(depth0,"inc"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":39,"column":45},"end":{"line":39,"column":61}}}))
    + "\" width=\"560\" height=\"349\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"video") || (depth0 != null ? lookupProperty(depth0,"video") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"video","hash":{},"data":data,"loc":{"start":{"line":39,"column":93},"end":{"line":39,"column":104}}}) : helper)))
    + "?enablejsapi=1\" frameborder=\"0\"></iframe>\n              </div> -->\n            </div>\n\n            <div class=\"col-12 col-lg-4\">\n\n              <div class=\"question\">\n                <img class=\"bg\" src=\"bubble.png\" />\n                <h3>"
    + alias2(((helper = (helper = lookupProperty(helpers,"q") || (depth0 != null ? lookupProperty(depth0,"q") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"q","hash":{},"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":47,"column":27}}}) : helper)))
    + "</h3>\n                "
    + alias2((lookupProperty(helpers,"setIndex")||(depth0 && lookupProperty(depth0,"setIndex"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"setIndex","hash":{},"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":48,"column":35}}}))
    + "\n                <div class=\"options\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"a") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":18},"end":{"line":53,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"media-wrapper\">\n                <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":33,"column":37}}}) : helper)))
    + "\" alt=\"\">\n              </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <input type=\"radio\" class=\"btn-check\" name=\"options\" id=\"l"
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"level") : depths[2]), depth0))
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"fag") : depths[1]), depth0))
    + "s"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "v"
    + alias2((lookupProperty(helpers,"inc")||(depth0 && lookupProperty(depth0,"inc"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":51,"column":122},"end":{"line":51,"column":136}}}))
    + "\" autocomplete=\"off\">\n                  <label class=\"btn btn-outline-primary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"correct") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":52,"column":56},"end":{"line":52,"column":112}}})) != null ? stack1 : "")
    + "\" for=\"l"
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"level") : depths[2]), depth0))
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"fag") : depths[1]), depth0))
    + "s"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "v"
    + alias2((lookupProperty(helpers,"inc")||(depth0 && lookupProperty(depth0,"inc"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":52,"column":167},"end":{"line":52,"column":181}}}))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"option") : depth0), depth0)) != null ? stack1 : "")
    + "</label>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "btn-correct";
},"7":function(container,depth0,helpers,partials,data) {
    return "btn-wrong";
},"9":function(container,depth0,helpers,partials,data) {
    return "          <h2>Tillykke</h2>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "          <h2>Godt gået!</h2>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "              <p>Du har nu gennemført spildevandsspillet.</p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "              <p>Du har svaret rigtigt på alle spørgsmålene.<br>\n              Prøv at se, om du også kan svarerne på næste niveau.</p>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "              <img src=\"dyr-egern.png\" style=\"max-width:100%;height:auto;\" alt=\"\">\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "              <img src=\"dyr-sael.png\" style=\"max-width:100%;height:auto;\" alt=\"\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-wrapper\" id=\"page-level"
    + alias4(((helper = (helper = lookupProperty(helpers,"level") || (depth0 != null ? lookupProperty(depth0,"level") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"level","hash":{},"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":51}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"subject") || (depth0 != null ? lookupProperty(depth0,"subject") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subject","hash":{},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":63}}}) : helper)))
    + "\">\n  <div>\n    <form>\n      <header class=\"container\">\n        <h1>Spildevandsspillet <span class=\"level-indicator\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"level") || (depth0 != null ? lookupProperty(depth0,"level") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"level","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":72}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"subject") || (depth0 != null ? lookupProperty(depth0,"subject") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subject","hash":{},"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":86}}}) : helper)))
    + "</span></h1>\n      </header>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"questions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":60,"column":13}}})) != null ? stack1 : "")
    + "\n    <div class=\"step\" id=\"page-level"
    + alias4(((helper = (helper = lookupProperty(helpers,"level") || (depth0 != null ? lookupProperty(depth0,"level") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"level","hash":{},"data":data,"loc":{"start":{"line":62,"column":36},"end":{"line":62,"column":47}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"subject") || (depth0 != null ? lookupProperty(depth0,"subject") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subject","hash":{},"data":data,"loc":{"start":{"line":62,"column":48},"end":{"line":62,"column":61}}}) : helper)))
    + "-summary\" data-subject=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fag") || (depth0 != null ? lookupProperty(depth0,"fag") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fag","hash":{},"data":data,"loc":{"start":{"line":62,"column":85},"end":{"line":62,"column":94}}}) : helper)))
    + "\">\n      <div class=\"container content-wrapper\">\n        <header class=\"row\">\n          <div class=\"text-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isLevel3")||(depth0 && lookupProperty(depth0,"isLevel3"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"level") : depth0),{"name":"isLevel3","hash":{},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":32}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":66,"column":10},"end":{"line":70,"column":17}}})) != null ? stack1 : "")
    + "          </div>\n\n          <div class=\"col\">\n            <div class=\"status-bar\">\n              <div class=\"status-bar-progress\" style=\"width: 100%;\"></div>\n            </div>\n          </div>\n        </header>\n        <div class=\"row\">\n          <div class=\"col-12 col-lg-8\">\n            <p>\n              <b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"fag") || (depth0 != null ? lookupProperty(depth0,"fag") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fag","hash":{},"data":data,"loc":{"start":{"line":82,"column":17},"end":{"line":82,"column":26}}}) : helper)))
    + "</b><br>\n              <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"video") || (depth0 != null ? lookupProperty(depth0,"video") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"video","hash":{},"data":data,"loc":{"start":{"line":83,"column":23},"end":{"line":83,"column":34}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"videotekst") || (depth0 != null ? lookupProperty(depth0,"videotekst") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videotekst","hash":{},"data":data,"loc":{"start":{"line":83,"column":52},"end":{"line":83,"column":68}}}) : helper)))
    + "</a><br><br>\n            </p>\n          </div>\n        </div>\n\n        <div class=\"row\">\n          <div class=\"col-12 col-lg-8\">\n            <div class=\"media-wrapper\">\n              <div class=\"text-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isLevel3")||(depth0 && lookupProperty(depth0,"isLevel3"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"level") : depth0),{"name":"isLevel3","hash":{},"data":data,"loc":{"start":{"line":92,"column":20},"end":{"line":92,"column":36}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":92,"column":14},"end":{"line":97,"column":21}}})) != null ? stack1 : "")
    + "              </div>\n            </div>\n          </div>\n\n          <div class=\"col-12 col-lg-4\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isLevel3")||(depth0 && lookupProperty(depth0,"isLevel3"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"level") : depth0),{"name":"isLevel3","hash":{},"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":103,"column":36}}}),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":103,"column":14},"end":{"line":107,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n\n\n      <footer>\n        <button class=\"btn btn-primary btn-lg\" type=\"button\" id=\"next-button\">Næste opgave</button>\n        <button class=\"btn btn-link btn-lg\" type=\"button\" id=\"reset-button\">Spil mere</button>\n      </footer>\n    </form>\n  </div>\n</div>";
},"useData":true,"useDepths":true});
})();